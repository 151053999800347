*{
  margin: 0px;
}
.header-container {
  
  width: 100%;
  height: 130px;
  background-color: rgb(5, 5, 5);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.container-contato{
  width: 33%;
  text-align: center;
  color: rgb(241, 239, 239);
  font-size: 14px;
  display: flex;   
  justify-content: center; 
  flex-direction: column;
  align-items: center;
 

}
.contato{
  background-color: #c4a733;
  color: black;
  padding: 5px 5px;
  border-radius: 50%;
  width: 60%;
  margin-bottom: 2px;
  font-size: 16px;
}

.logo-container {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  max-width: 100%;
  height: 85px;
 
}

/* 
*/
.logo-container a {
  display: inline-block; /* Garante que o link ocupe apenas o espaço da imagem */
}

.logo-container img {
  transition: transform 0.3s; /* Adiciona uma transição suave */
}

.logo-container img:hover {
  transform: scale(1.05); /* Aumenta um pouco a imagem ao passar o mouse */
}

.hr-funcionamento{
  width: 33%;
  text-align: center;
  color: aliceblue;
}

.hr-funcionamento h3{
  color: aliceblue;
}

/* */
.container-menu{
  width: 100%;
  height: auto;
  background-color: rgb(15, 15, 15);
  
  
}

nav ul {
  display: flex;
  justify-content: center;
  list-style: none;
  flex-wrap: wrap;
  border-top: 1px solid rgb(49, 49, 49);

}

nav ul li {
border-right: 1px solid rgb(61, 61, 61);
margin-right: 20px;
padding: 2px 5px;
  
  }


nav ul a {
  text-decoration: none;
  color: azure;
  font-size: 18px;
  
}


.menu-toggle {
  display: none;
  background-color: transparent;
  border: none;
  color: azure;
  font-size: 30px;
  cursor: pointer;
  position: absolute; /* Para manter o menu-toggle no centro do container */
}




/* Media query para dispositivos móveis */
@media (max-width: 768px) {
  .header-container {
    height: auto; /* Altura automática */
    padding: 10px 0; /* Espaçamento interno */
    
  }

  .menu-toggle {
    display: block;
    top: 80px; /* Ajuste conforme necessário para centralizar verticalmente */
    left: 15%;
    transform: translateX(-50%);
    
    
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  nav ul li {
    border: none;
    margin: 0;
    padding: 10px 0;
    text-align: center;
  }

  nav ul.active {
    display: flex;
  }


  .container-contato,
  .hr-funcionamento {
    display: none;
  }

 
  .logo-container
  {
    width: 100%; /* Largura total */
    text-align: center; /* Alinhamento central */
    margin-bottom: 20px; /* Espaçamento inferior */
  }

}