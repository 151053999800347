*{
    margin: 0;;
}

.container-footer{
    color: aliceblue;
    background-color: rgb(5, 5, 5);
    display: flex;
    justify-content: center;
   
}

.form-pagamento{
    width: 25%;
    padding: 5px 3px;
    text-align: center;
    border-right: 1px solid rgb(51, 51, 51);
}

.Localizacao{
    width: 25%;
    padding: 5px 3px;
    text-align: center;
    border-right: 1px solid rgb(51, 51, 51);
}

.taxa-entrega{
    width: 25%;
    padding: 5px 3px;
    text-align: center;
    border-right: 1px solid rgb(51, 51, 51);
}

.hrdefuncionamento{
    width: 25%;
    padding: 5px 3px;
    text-align: center;
  
}

@media (max-width: 768px) {
    .form-pagamento, .Localizacao, .hrdefuncionamento, .taxa-entrega {
        width: 100%;
        border-bottom: 1px solid white;
       
      
    }

    .container-footer{
        display: flex;
        flex-direction: column;
       
    }
}