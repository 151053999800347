/* estilobovino.css */

.bovino-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center; /* Centraliza os cards horizontalmente */
    
  }

  .container-titulo{
    width: 100%;
  }
  
  .destaque-titulo {
    text-align: center;
    font-size: 24px;
    margin-bottom: 5px; /* Adiciona espaço abaixo do título */
    margin-top: 10px;
    width: 100%;
  }
  
.linha {
    width: 100%;
    display: flex; /* Usar flexbox para centralizar o elemento */
    justify-content: center; /* Centralizar horizontalmente */
    margin-top: 10px; /* Adiciona espaço acima da linha */
    margin-bottom: 20px;
}

.linha-transparente {
    width: 80%; /* Define a largura da linha */
    border-top: 2px solid rgba(131, 127, 127, 0.5); /* Adiciona uma borda superior vermelha */
    transition: width 0.5s ease; /* Adiciona uma transição suave na largura */
    
}

.container-card-pai{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centraliza os cards horizontalmente */
    align-items: center;
}
  
.produto-card {
  width: 12%;
  margin: 0 2%;
  text-align: center;
  background-color: rgb(253, 253, 253);
  margin-bottom: 10px;
  border-radius: 5%;
  border: 1px solid rgb(209, 205, 205);

  /* Adiciona efeito 3D */
  transform: perspective(1000px) rotateY(0deg) rotateX(0deg);
  transition: transform 0.5s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona sombra */
}

.produto-card:hover {
  /* Rotação 3D ao passar o mouse */
  transform: perspective(1000px) rotateY(1deg) rotateX(1deg) translateY(-5px);
}

  
  .produto-card img {
    width: 100%;
    height: 110.75px;
    border-bottom: 1px solid rgb(194, 183, 183);
    border-radius: 5%;
    
  }

  .produto-card p{
    font-size: 80%;
    padding: 5px 5px 5px 5px;
    color: black;
  }

  .produto-card h3{
    font-size: 70%;
  }

  button{
    background-color: rgb(5, 133, 5);
    width: auto;
    padding: 7px 7px;
    font-size: 15px;
    border: none;
    border-radius: 10%;
    color: rgb(250, 253, 255);
    margin-bottom: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transição suave */

  }

  .produto-card button:hover {
    background-color: #45a049; /* Cor de fundo ao passar o mouse */
  }
  
  /* Tela para celular */

  
/* Media queries para telas menores */
@media (max-width: 768px) {
  .produto-card {
    width: 42%; /* Cards ocupam toda a largura disponível em telas menores */
    margin: 8px 8px;
  }
}
